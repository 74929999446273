import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Manage from "layouts/Manage.js";
import LoginPage from "views/Login/LoginPage.js"
import { PrivateRoute } from 'components/PrivateRoute';
import { Logout } from 'components/Logout'

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/logout" component={Logout} />
      <PrivateRoute path="/manage" component={Manage} />
      <Redirect from="/" to="/manage" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
