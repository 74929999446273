/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
          <p className={classes.right}>
          <span>
            {'Copyright © ' + new Date().getFullYear() + ' '}
            Pour
          </span>
        </p>
      </div>
    </footer>
  );
}
