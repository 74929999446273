import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import GridList from '@material-ui/core/GridList';
import { GridListTile } from '@material-ui/core';
import { lighten, withStyles } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from "@material-ui/core/Menu";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NewKegModal from 'components/NewKegModal/NewKegModal'

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { pourService } from "_services";

import moment from "moment";

let compareBy = (key) => {
  return function(a, b) {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  }
}

const useStyles = makeStyles(styles);
const classes = withStyles(styles);

class Taps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLocations: [],
      taps: [],
      isLoading: true,
      location: null
    };
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  loadTapList() {
    if (this.state.location == null || this.state.location.id == null || this.state.location.id <= 0) {
      return
    }

    pourService.getTapList(this.state.location.id).then(r => mapPourResponse(r))
    .then(data => this.setState({ isLoading: false, taps: data }))
  }

  componentDidMount() {
    //TODO: factor this apart
    var lastLocStr = localStorage.getItem('lastLocation')
    var lastLoc = lastLocStr ? Number.parseInt(lastLocStr) : 0
    let user = JSON.parse(localStorage.getItem('currentUser'))
    
    pourService.getAllLocations()
    .then(locs => { 
      if (!locs || locs.length <= 0) {
        this.setState({ isLoading: false })
        return Promise.reject("done")
      }
      return locs.filter(loc => user.principal.tapLocations.includes(loc.id.toString())) })
    .then(flocs => {
      if (!flocs || flocs.length <= 0) {
        this.setState({ isLoading: false })
        return Promise.reject("done")
      }
      return flocs })
    .then(flocs => {return flocs.sort(compareBy('name'))})
    .then(flocs => {
      var loadLoc = lastLoc > 0 ? flocs.find(l => l.id === lastLoc) ?? flocs[0] : flocs[0]
      this.setState({ allLocations: flocs, location: loadLoc }, () => this.loadTapList())
    })
  }

  handleLocationChange(e){
    localStorage.setItem('lastLocation', e.target.value);
    this.setState({location: this.state.allLocations.find(l => l.id === e.target.value)}, () => {
      this.loadTapList()
    });
  }

render() {
  const { taps, isLoading } = this.state;

  if (isLoading) {
    return <p>Loading ...</p>;
  }

  if (!this.state.allLocations || this.state.allLocations.length <= 0 || !this.state.location || !this.state.location.id || this.state.location.id <= 0) {
    return <p>No locations available.</p>;
  }

  taps.sort(function(a, b){return a.id-b.id});
  document.title = "Manage Taps - pour"
  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select value={this.state.location.id} onChange={this.handleLocationChange} displayEmpty className={classes.selectEmpty}>
          <MenuItem value="" disabled>
            Location
          </MenuItem>
          {this.state.allLocations.map(loc => 
            <MenuItem value={loc.id} key={"locsel-"+loc.id}>{loc.name}</MenuItem>
          )}
        </Select>
      </FormControl>
      <h2> </h2>
        <GridList cols={1} spacing={'10'}> {/*TODO: this works? numeric spacing values don't behave correctly. Fucking yavascript*/}
          {taps.map(tap =>
            <GridListTile key={tap.locationId + "-tap-" + tap.id + "-tile"}>
            <KCard canExpand={tap.currentKeg.id > 0} currentEntity={this.state.location.entity} onUpdateTapList={() => {this.loadTapList()}} tap={tap} /> 
            </GridListTile>
          )}
        </GridList>
    </div>
  )}
}

export default Taps;

const mySwal = withReactContent(Swal)

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#4c0099', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#4c0099',
  },
})(LinearProgress);

class KCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      expanded: false,
      tap: this.props.tap,
    };
  }

  updateKegEditorState(keg) {
    this.setState({kegEditor: keg})
  }

  handleAssignClick = (e) => {
    e.stopPropagation();
    this.setState({ anchorEl: null })

    mySwal.fire({
      cancelButtonColor: '#d33',
      cancelButtonText: "Cancel",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Create',
      html: <NewKegModal onUpdateKeg={(keg) => this.updateKegEditorState(keg)} />,
      preConfirm: this.validateNewKeg,
      showCancelButton: true,
      title: "New Keg",
    }).then((result) => {
      if (result.value) {
        var newKeg = this.state.kegEditor
        newKeg.entity = { id: this.props.currentEntity.id }

        //TODO This is super gross
        var kPromise = pourService.createKeg(newKeg)
        var tapPromise = pourService.getTap(this.props.tap.locationId, this.props.tap.tapId)
        Promise.all([kPromise, tapPromise])
        .then(results => {
          var newKegId = results[0].id
          var tap = results[1]
          tap.currentKeg = {id: newKegId}
          return tap })
        .then(tap => { return pourService.updateTap(tap) })
        .then(() => { this.props.onUpdateTapList() })
      }})
  }

  handleEditClick = (e, k) => {
    e.stopPropagation();
    this.setState({ anchorEl: null })

    mySwal.fire({
      cancelButtonColor: '#d33',
      cancelButtonText: "Cancel",
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Update',
      html: <NewKegModal editor={k} onUpdateKeg={(keg) => this.updateKegEditorState(keg)} />,
      preConfirm: this.validateNewKeg,
      showCancelButton: true,
      title: "Edit Keg",
    }).then((result) => {
      if (result.value) {
        var editedKeg = this.state.kegEditor
        editedKeg.entity = { id: this.props.currentEntity.id }
        pourService.updateKeg(editedKeg).then(() => { this.props.onUpdateTapList() }) 
      }})
    }

  validateNewKeg = () => {
    if (!this.state.kegEditor || !this.state.kegEditor.beer || !this.state.kegEditor.beer.id || this.state.kegEditor.beer.id <= 0) {
      mySwal.showValidationMessage("You must select a beer")
      return false
    }
    if (!this.state.kegEditor.package || !this.state.kegEditor.package.id || this.state.kegEditor.package.id <= 0) {
      mySwal.showValidationMessage("You must select a keg size")
      return false
    }
    if (!this.state.kegEditor.package || !this.state.kegEditor.package.id || this.state.kegEditor.package.id <= 0) {
      mySwal.showValidationMessage("You must select a keg size")
      return false
    }
    if (this.state.kegEditor.purchaseDeposit && this.state.kegEditor.purchaseDeposit < 0) {
      mySwal.showValidationMessage("Deposit cannot be negative")
      return false
    }
    if (this.state.kegEditor.purchasePrice && this.state.kegEditor.purchasePrice < 0) {
      mySwal.showValidationMessage("Purchase price cannot be negative")
      return false
    }
    return true
  }

  handleKickClick = (e) => {
    e.stopPropagation();
    const beerName = this.props.tap && this.props.tap.currentKeg && this.props.tap.currentKeg.id > 0 ? this.props.tap.currentKeg.beer.name : '';

    this.setState({ anchorEl: null },
      () => mySwal.fire({
        title: "Mark Keg Kicked?",
        text: "Are you sure you want to mark " + beerName + " as kicked? Don't worry, it will still be in your history, we just don't let you see that right now because the developer is lazy AF.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: "Shit, no.",
        confirmButtonText: 'Fuck yeah, I do!',
      }).then((result) => {
        if (result.value) {
          this.handleKickConfirm()
      }})
    )
  }

  handleKickConfirm = () => {
    pourService.getKeg(this.props.tap.currentKeg.id)
    .then(keg => {keg.kickDate = new Date().toISOString(); return keg;})
    .then(keg => {return pourService.updateKeg(keg)})
    .then(() => {return pourService.getTap(this.props.tap.locationId, this.props.tap.tapId)})
    .then(tap => {tap.currentKeg = null; return tap;})
    .then(tap => {return pourService.updateTap(tap)})
    .then(() => {this.props.onUpdateTapList()});
  }

  handleOpenMenu = e => {
    e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  };

  handleCloseMenu = e => {
    e.stopPropagation();
    this.setState({ anchorEl: null });
  };

  setExpanded = (ex) => {
    this.setState({ expanded: ex });
  }

  render() {
    const { anchorEl, expanded } = this.state;
    const setExpanded = this.setExpanded;
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const isTapped = this.props.tap && this.props.tap.currentKeg && this.props.tap.currentKeg.id > 0;
    
    return (
      <Card className={classes.card} width={1} onClick={this.props.canExpand ? handleExpandClick : null} key={this.props.key}>
        <CardHeader
          avatar={
            <Avatar aria-label="tap name" className={classes.avatar}>
              {this.props.tap.id}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings" onClick={(e) => this.handleOpenMenu(e)}>
              <MoreVertIcon/>
            </IconButton>
          }
          title={this.props.tap.currentKeg.beer.name}
          subheader={this.props.tap.currentKeg.beer.brewery.name}
        />
        <Menu id="tap-edit-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={e => this.handleCloseMenu(e)}>
          <MenuItem disabled={!isTapped} onClick={(e) => this.handleEditClick(e, this.props.tap.currentKeg)}>Edit Keg</MenuItem>
          <MenuItem disabled={!isTapped} onClick={(e) => this.handleKickClick(e)}>Kicked</MenuItem>
          <MenuItem disabled={isTapped} onClick={(e) => this.handleAssignClick(e)}>New Keg</MenuItem>
        </Menu>
        <KStatus tap={this.props.tap} />
        <Collapse in={this.props.canExpand && expanded} timeout="auto" unmountOnExit key={this.props.tap.currentKeg.id + "-plcontent"}>
          <CardContent>
            <KegPourList kegId={this.props.tap.currentKeg.id} />
          </CardContent>
        </Collapse>
      </Card>
    );
}}

function KStatus(props) {
  const classes = useStyles();

  if (props.tap.currentKeg.beer.name === "Open") {
    return null
  }

  return (
    <CardContent>
    <BorderLinearProgress className={classes.margin} variant="determinate" color="secondary" value={props.tap.currentKeg.percentRemaining} />
    <Typography className={classes.pos} color="textSecondary">
        {props.tap.currentKeg.percentRemaining}% ({Math.round(props.tap.currentKeg.volumeRemaining/16.0)} Pints) Remaining
      </Typography>
    </CardContent>
  );
}

class KegPourList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pours: [],
      isLoading: false,
      kegId: props.kegId
    };
  }

  componentDidMount () {
    if (this.props.kegId <= 0){
      return
    }
    this.refreshPours()
  }

  handleDeletePourClick = (e, kegId, pourId) => {
    e.stopPropagation();
    pourService.deleteKegPour(kegId, pourId)
    .then(() => this.refreshPours())
  };

  refreshPours = () =>  {
    pourService.getPours(this.props.kegId)
    .then(data => this.setState({ pours: data }));
  }

  render() {
    const { pours } = this.state;
    const poursToShow = 5;

    return (
    <List className={classes.root} dense={true} disablePadding={true} key={this.props.kegId + "-pours"}>
      {pours.slice(0,poursToShow).map((kp,i) =>
        <ListItem divider={i !== poursToShow-1} key={this.props.kegId + "-" + kp.id + "-pour"}>
          <ListItemText primary ={(kp.volumeMl * ozPerMl).toFixed(1) + "oz"} secondary={moment(new Date(kp.timestamp)).calendar(null, {
            sameDay: '[Today] [at] LT',
            nextDay: 'MM/DD/YYYY [at] LT',
            nextWeek: 'MM/DD/YYYY [at] LT',
            lastDay: '[Yesterday] [at] LT',
            lastWeek: 'dddd [at] LT',
            sameElse: 'MM/DD/YYYY'})} 
          />
          <ListItemSecondaryAction onClick={(e) => this.handleDeletePourClick(e, this.props.kegId, kp.id)}>
            <IconButton edge="end" aria-label="comments">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  )}
}

function mapPourResponse(resp) {
  return resp.map(i => ({
    id: i.name, //TODO: need to map this out to the correct values
    tapId: i.id,
    locationId: i.locationId,
    currentKeg: mapKeg(i.currentKeg)
  }))
}

function mapKeg(keg) {
  if (!keg || keg == null) {
    return { id: 0, beer: { name: "Open", brewery: { name: "" } } } 
  }
  keg["percentRemaining"] =  Number(((keg.package.volumeMl - keg.volumePouredMl) / keg.package.volumeMl * 100).toFixed(1))
  keg["volumeRemaining"] = ((keg.package.volumeMl - keg.volumePouredMl) * ozPerMl)
  return keg
}

const ozPerMl = 0.033814