import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { pourService } from "_services";
import moment from "moment";

class InventorySheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        inv:[{"Brewery":"","Beer":"","Style":"","Package":"","Distributor":"","OnHand":"","Price":"","AsOf":""},
            ]
        }
}

 componentDidMount() {
   pourService.getInventory()
  .then(d => this.setState({inv: d.map(i =>({
    ...i,
    price: ('$' + parseFloat(Math.round(i.price * 100)/100).toFixed(2)),
    retailPrice: '$' + parseFloat(Math.round(i.price * 115) / 100).toFixed(2),
    inventoryTimestamp: moment().to(new Date(i.inventoryTimestamp))
  }))}))
 }
  render() {
      document.title = "Keg Inventory - pour"
    return (
    <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            Distributor Keg Inventory
          </CardHeader>
          <CardBody>
          <MaterialTable
          columns={[
            { title: "Brewery", field: "beer.brewery.name" },
            { title: "Beer", field: "beer.name", grouping: false },
            { title: "Style", field: "beer.style.style" },
            { title: "Package", field: "package.name" },
            { title: "Distributor", field: "distributor.name" },
            { title: "On Hand", field: "quantity", grouping: false, searchable: false },
            { title: "Price", field: "price", customSort: (a, b) => Number(a.price.substring(1)) - Number(b.price.substring(1)), grouping: false, searchable: false },
            { title: "Retail Price", field: "retailPrice", customSort: (a, b) => Number(a.retailPrice.substring(1)) - Number(b.retailPrice.substring(1)), grouping: false, searchable: false },
            { title: "As Of", field: "inventoryTimestamp", grouping: false, searchable: false },
          ]}
          data={this.state.inv}
          title="Kegs"
          options={{
              grouping: true,
              pageSize: 10,
              search: true,
              }}
            />
            </CardBody>
            </Card>
        </GridItem>
      </GridContainer>
  );
  }
}

export default InventorySheet;
