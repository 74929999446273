// @material-ui/icons
import InventorySheet from "views/InventorySheet/InventorySheet.js";
import Taps from "views/Taps/Taps.js";
import BeerAdmin from "views/BeerAdmin/BeerAdmin";

const dashboardRoutes = [
  {
    path: "/taps",
    name: "Taps",
    icon: "local_drink_sharp",
    component: Taps,
    layout: "/manage",
    role: "taps"
  },
  {
    path: "/inventory",
    name: "Inventory",
    icon: "content_paste",
    component: InventorySheet,
    layout: "/manage",
    role: "inventory"
  },
  {
    path: "/beers",
    name: "Manage Beers",
    icon: "edit",
    component: BeerAdmin,
    layout: "/manage",
    role: "admin"
  }
];

export default dashboardRoutes;
