import { authHeader, handleResponse } from '_helpers';

const apiUrl = process.env.REACT_APP_POUR_API
export const pourService = {
    createBeer,
    createIdLookup,
    createKeg,
    deleteIdLookup,
    deleteKegPour,
    getAllBeers,
    getAllBreweries,
    getAllEntities,
    getAllLocations,
    getAllPackages,
    getAllStyles,
    getBeer,
    getKeg,
    getIdLookupForBeer,
    getInventory,
    getPours,
    getTap,
    getTapList,
    updateBeer,
    updateKeg,
    updateTap
};

function getAllBeers() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/beers`, requestOptions).then(handleResponse);
}

function getBeer(beerId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/beers/${beerId}`, requestOptions).then(handleResponse);
}

function createBeer(beer) {
    const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(beer) 
        };
    return fetch(`${apiUrl}/beers`, requestOptions).then(handleResponse);
}

function createKeg(keg) {
    const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(keg) 
        };
    return fetch(`${apiUrl}/kegs`, requestOptions).then(handleResponse);
}

function getAllStyles(){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/styles`, requestOptions).then(handleResponse);
}

function getAllBreweries(){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/breweries`, requestOptions).then(handleResponse);
}

function getInventory(){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/inventory`, requestOptions).then(handleResponse);
}

function createIdLookup(lookup) {
    const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(lookup) 
        };
    return fetch(`${apiUrl}/entities/${lookup.entityId}/idlookup`, requestOptions).then(handleResponse);
}

function deleteIdLookup(entityId, lookupId) {
    const requestOptions = {
            method: 'DELETE',
            headers: authHeader(),
        };
    return fetch(`${apiUrl}/entities/${entityId}/idlookup/${lookupId}`, requestOptions).then(handleResponse);
}

function deleteKegPour(kegId, pourId) {
    const requestOptions = {
            method: 'DELETE',
            headers: authHeader(),
        };
    return fetch(`${apiUrl}/kegs/${kegId}/pours/${pourId}`, requestOptions).then(handleResponse);
}

function getIdLookupForBeer(beerId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/beers/${beerId}/idlookup`, requestOptions).then(handleResponse);
}

function getAllPackages(){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/packages`, requestOptions).then(handleResponse);
}

function getAllEntities(){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/entities`, requestOptions).then(handleResponse);
}

function getAllLocations(){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/locations`, requestOptions).then(handleResponse);
}

function getKeg(kegId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/kegs/${kegId}`, requestOptions).then(handleResponse);
}

function getTap(locationId, tapId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/locations/${locationId}/taps/${tapId}`, requestOptions).then(handleResponse);
}

function getTapList(locationId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/locations/${locationId}/taps`, requestOptions).then(handleResponse);
}

function getPours(kegId) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${apiUrl}/kegs/${kegId}/pours`, requestOptions).then(handleResponse);
}

function updateBeer(beer) {
    const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(beer)
        };
    return fetch(`${apiUrl}/beers/${beer.id}`, requestOptions).then(handleResponse);
}

function updateKeg(keg) {
    const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(keg)
        };
    return fetch(`${apiUrl}/kegs/${keg.id}`, requestOptions).then(handleResponse);
}

function updateTap(tap) {
    const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify(tap)
        };
    return fetch(`${apiUrl}/locations/${tap.locationId}/taps/${tap.id}`, requestOptions).then(handleResponse);
}