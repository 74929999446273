import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { pourService } from '_services';
import matchSorter from 'match-sorter'

const compareIdLookups = (left, right) => {
  return left.entity.id === right.entity.id 
          && left.package.id === right.package.id
          && left.externalId === right.externalId 
};

class UpdateBeerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          beername:'',
          brewery: {name:'', id:-1},
          style: {style:'', id:-1},
          abv: '',
          externalIDs: [],
          allStyles: [],
          allBreweries: []
        }
        this.handleBreweryChange = this.handleBreweryChange.bind(this)
        this.handleStyleChange = this.handleStyleChange.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.setInitialBeerState = this.setInitialBeerState.bind(this);
        this.setInitialIdLookupState = this.setInitialIdLookupState.bind(this);
        this.setWorkingBeer = this.setWorkingBeer.bind(this);
        this.shouldUpdateBeer = this.shouldUpdateBeer.bind(this);
    }

    //TODO: helperfn
    compareBy = (key) => {
      return function(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      };
    };



    componentDidMount() {
      pourService.getAllStyles().then(s => {
        let sCp = [...s].sort(this.compareBy('style'));
        this.setState({ allStyles: sCp })
      });

      pourService.getAllBreweries().then(b => {
        let bCp = [...b].sort(this.compareBy('name'));
        this.setState({ allBreweries: bCp })
      });

      pourService.getAllEntities().then(e => {
        let eCp = [...e].sort(this.compareBy('name'));
        this.setState({ allEntities: eCp })
      });

      pourService.getAllEntities().then(e => {
        let eCp = [...e].sort(this.compareBy('name'));
        this.setState({ allEntities: eCp })
      });

      pourService.getAllPackages().then(p => {
        let pCp = [...p].sort(this.compareBy('name'));
        this.setState({ allPackages: pCp })
      });

      this.setWorkingBeer(this.props.beerId)
   }

   setWorkingBeer(beerId) {
    pourService.getBeer(beerId).then(b => this.setInitialBeerState(b))
    pourService.getIdLookupForBeer(beerId).then(lu => this.setInitialIdLookupState(lu))
   }

  componentDidUpdate(prevProps) {
    if(this.props.beerId !== prevProps.beerId) {
      this.setWorkingBeer(this.props.beerId)
    }
  } 

  setInitialBeerState(beer) {
    this.setState({
      initialBeer: beer,
      beername: beer.name,
      brewery: beer.brewery,
      style: beer.style,
      abv: beer.abv,
      })
  }

   setInitialIdLookupState(lookup) {
    this.setState({initialIdLookup: lookup, externalIDs: lookup})
   }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleBreweryChange(e, v) {
    this.setState({
      brewery: v
    })
  }

  handleStyleChange(e, v) {
    this.setState({
      style: v
    })
  }

  shouldUpdateBeer(beer1, beer2){
    return beer1.brewery.id !== beer2.brewery.id || beer1.name !== beer2.name || beer1.abv !== beer2.abv || beer1.style.id !== beer2.style.id
  }

  handleSubmit(event) {
    var newBeer = { 
      abv: parseFloat(this.state.abv),
      brewery: this.state.brewery,
      id: this.state.initialBeer.id,
      name: this.state.beername,
      style: this.state.style,
    }

    if (this.shouldUpdateBeer(this.state.initialBeer, newBeer)){
      pourService.updateBeer(newBeer)
    }

    //TODO: handle updates, or make existing rows non-editable
    this.state.externalIDs.forEach(xId => {
      if ((!xId.id || xId.id === 0) && !this.state.initialIdLookup.find(i => compareIdLookups(i, xId))) {
          var newLu = {
            entityId: xId.entity.id,
            beerId: this.state.initialBeer.id,
            packageId: xId.package.id,
            externalId: xId.externalId
          }
          pourService.createIdLookup(newLu)
      } else {
        //TODO
      }
    })
    this.state.initialIdLookup.forEach(xId => {
      if (!this.state.externalIDs.find(i => i.id === xId.id)){
        pourService.deleteIdLookup(xId.entity.id, xId.id)
      }
    })
    this.props.onSave()
    event.preventDefault();
  }

  handleCancel(event){
    this.props.onCancel();
    event.preventDefault();
  }

  handleAddExternalId = () => {
    this.setState({
      externalIDs: this.state.externalIDs.concat([{ id: 0, entity: {name:"", id:-1}, package:{name:"",id:-1}, externalId: "" }])
    });
  };

  handleRemovexternalId = idx => () => {
    this.setState({
        externalIDs: this.state.externalIDs.filter((_x, sidx) => idx !== sidx)
    });
  };

  handleExternalIDEntityChange = idx => (_e,v) => {
    const newExternalIDs = this.state.externalIDs.map((xid, sidx) => {
      if (idx !== sidx) return xid;
      return { ...xid, entity: v };
    });
    this.setState({ externalIDs: newExternalIDs });
  };

  handleExternalIDPackageChange = idx => (_e,v) => {
    const newExternalIDs = this.state.externalIDs.map((xid, sidx) => {
      if (idx !== sidx) return xid;
      return { ...xid, package: v };
    });
    this.setState({ externalIDs: newExternalIDs });
  };

  handleExternalIDExtIDChange = idx => (e) => {
    const newExternalIDs = this.state.externalIDs.map((xid, sidx) => {
      if (idx !== sidx) return xid;
      return { ...xid, externalId: e.target.value };
    });
    this.setState({ externalIDs: newExternalIDs });
  };

  resetForm(){
    this.setState({
      brewery: {name:'', id:''},
      beername: '',
      abv: '',
      style: {style:'', id:-1},
      externalIDs: [],
      })
  }

  render() {
    const filterOptions = (options, { inputValue }) =>
      matchSorter(options, inputValue, {keys:['style'], threshold: matchSorter.rankings.ACRONYM});

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField name="beername" label="name" value={this.state.beername} onChange={this.handleChange} />
        <br/>
        <TextField
            name="abv"
            label="ABV"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            style={{step: 0.1}}
            step={0.1}
            onChange={this.handleChange}
            value={this.state.abv}
            margin="normal"
        />
        <br/>
        <Autocomplete
            value={this.state.style}
            options={this.state.allStyles}
            getOptionLabel={s => s.style}
            style={{ width: 300 }}
            name="style"
            filterOptions={filterOptions}
            onChange={this.handleStyleChange}
            autoSelect={true}
            renderInput={params => (
                <TextField {...params} label="" variant="outlined" name="styleinner" fullWidth />
            )}
        />
        <br/>
        <Autocomplete
            value={this.state.brewery}
            options={this.state.allBreweries}
            getOptionLabel={option => option.name}
            name="brewery"
            label="Brewery"
            style={{ width: 300 }}
            onChange={this.handleBreweryChange}
            autoSelect={true}
            renderInput={params => (
                <TextField {...params} variant="outlined" value={this.state.brewery} fullWidth />
            )}
        />
        <br/>
        <div>
            <h6>External IDs</h6>
            {this.state.externalIDs.map((xi, idx) => (
            <div className="xidrow" key={"xi-row-" + idx}>
                <Autocomplete
                    value={xi.entity}
                    options={this.state.allEntities}
                    getOptionLabel={e => e.name}
                    style={{ width: 300, display:"inline-block", margin:"10px" }}
                    onChange={this.handleExternalIDEntityChange(idx)}
                    autoSelect={true}
                    disableClearable={true}
                    renderInput={params => (
                        <TextField {...params} label="" variant="outlined" name="styleinner" fullWidth />
                    )}
                />
                <Autocomplete
                    value={xi.package}
                    options={this.state.allPackages}
                    getOptionLabel={p => p.name}
                    style={{ width: 300, display:"inline-block", margin:"10px" }}
                    onChange={this.handleExternalIDPackageChange(idx)}
                    autoSelect={true}
                    disableClearable={true}
                    renderInput={params => (
                        <TextField {...params} value={xi.package} label="" variant="outlined" name="styleinner" fullWidth />
                    )}
                />
                <TextField label="id" value={xi.externalId} onChange={this.handleExternalIDExtIDChange(idx)} />
                <IconButton type="button" onClick={this.handleRemovexternalId(idx)}  className="small" variant="outlined"><RemoveIcon/></IconButton>
            </div>
            ))}
        </div>
        <IconButton type="button" onClick={this.handleAddExternalId} className="small" variant="outlined" color="primary" ><AddIcon/></IconButton>
        <br/>
        <br/>
        <br/>
        <Button type="submit" className="small" variant="contained" color="primary">SAVE</Button>
        <Button type="button" onClick={this.handleCancel} className="small" variant="contained" color="secondary">CANCEL</Button>

    </form>
  );
  }
}

export default UpdateBeerForm;
