import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { pourService } from '_services';

//TODO: helperfns, extract
let compareBy = (key) => {
  return function(a, b) {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  }
}

let floatOrNull = (strVal) => {
  return strVal && strVal.length > 0 ? Number.parseFloat(strVal) : null;
}

let isoDateOrNull = (strVal) => {
  return strVal && strVal.length > 0 ? new Date(strVal).toISOString() : null
}

let stringOrNull = (strVal) => {
  return strVal && strVal.length > 0 ? strVal : null;
}

class NewKegModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allBeers: [],
      allBreweries: [],
      allPackages: [],
      beer: props.editor ? props.editor.beer : null,
      brewery: props.editor ? props.editor.beer.brewery : null,
      kegId: props.editor ? props.editor.id : null,
      package: props.editor ? props.editor.package : null,
      packageDate: props.editor && props.editor.packageDate != null ? props.editor.packageDate.slice(0, 10) : '',
      purchasedFrom: props.editor && props.editor.purchasedFrom != null ? props.editor.purchasedFrom : '',
      purchaseDate: props.editor && props.editor.purchaseDate != null ? props.editor.purchaseDate.slice(0, 10) : '',
      purchaseDeposit: props.editor && props.editor.purchaseDeposit != null ? props.editor.purchaseDeposit : '',
      purchasePrice: props.editor  && props.editor.purchasePrice != null ? props.editor.purchasePrice : '',
    }

    this.handleBeerChange = this.handleBeerChange.bind(this)
    this.handleBreweryChange = this.handleBreweryChange.bind(this)
    this.handlePackageChange = this.handlePackageChange.bind(this)
    this.handlePackageDateChange = this.handlePackageDateChange.bind(this)
    this.handlePurchasedFromChange = this.handlePurchasedFromChange.bind(this)
    this.handlePurchaseDateChange = this.handlePurchaseDateChange.bind(this)
    this.handlePurchaseDepositChange = this.handlePurchaseDepositChange.bind(this)
    this.handlePurchasePriceChange = this.handlePurchasePriceChange.bind(this)
    this.updateKegState = this.updateKegState.bind(this)
  }

  updateKegState() {
    if (!this.props.onUpdateKeg) {
      return
    }
    this.props.onUpdateKeg({
      beer: this.state.beer && this.state.beer.id ? {id: this.state.beer.id} : null,
      id: this.state.kegId,
      package: this.state.package && this.state.package.id ? {id: this.state.package.id} : null,
      packageDate: isoDateOrNull(this.state.packageDate),
      purchasedFrom: stringOrNull(this.state.purchasedFrom),
      purchaseDate: isoDateOrNull(this.state.purchaseDate),
      purchaseDeposit: floatOrNull(this.state.purchaseDeposit),
      purchasePrice: floatOrNull(this.state.purchasePrice),
    })
  }

  handleBeerChange(_, v) {
    this.setState({ beer: v }, () => {
      this.updateKegState()
    })
  }
  
  handleBreweryChange(_, v) {
    this.setState({ beer: null, brewery: v })
  }
  
  handlePackageChange(_, v) {
    this.setState({ package: v }, () => {
      this.updateKegState()
    })
  }

  handlePackageDateChange(e) {
    this.setState({ packageDate: e.target.value }, () => {
      this.updateKegState()
    })
  }
  
  handlePurchasedFromChange(e) {
    this.setState({ purchasedFrom: e.target.value }, () => {
      this.updateKegState()
    })
  }

  handlePurchaseDateChange(e) {
    this.setState({ purchaseDate: e.target.value }, () => {
      this.updateKegState()
    })
  }

  handlePurchaseDepositChange(e) {
    this.setState({ purchaseDeposit: e.target.value }, () => {
      this.updateKegState()
    })
  }

  handlePurchasePriceChange(e) {
    this.setState({ purchasePrice: e.target.value }, () => {
      this.updateKegState()
    })
  };

  componentDidMount() {
    //TODO: this is hacky (thus the array/map crap is not optimized); fetch beers by brewery to optimize loads
    pourService.getAllBeers().then(b => {
        let bCp = [...b].sort(compareBy('name'));
        let brCp = Array.from(new Map([...b].map(b => [b.brewery.id, b.brewery])).values()).sort(compareBy('name'))
        this.setState({ allBeers: bCp, allBreweries: brCp })
    });

    pourService.getAllPackages().then(p => {
        let pCp = [...p].sort(compareBy('name'));
        this.setState({ allPackages: pCp })
    });
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container cols={1} spacing={1}>
          <Grid item xs={12}>
            <Autocomplete
              autoSelect={true}
              disabled={this.props.editor ? true : false}
              getOptionLabel={option => option && option.name ? option.name : ""}
              getOptionSelected={(o,v) => o === v || o.id === v.id}
              label="Brewery"
              name="brewery"
              onChange={this.handleBreweryChange}
              options={this.state.allBreweries}
              renderInput={params => <TextField {...params} label="Brewery" variant="outlined" value={this.state.brewery} fullWidth />}
              value={this.state.brewery}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              autoSelect={true}
              disabled={(this.props.editor && this.props.editor !== null) || this.state.brewery === undefined || this.state.brewery === null || this.state.brewery.id === undefined || this.state.brewery.id <= 0}
              getOptionLabel={option => option && option.name ? option.name : ""}
              getOptionSelected={(o,v) => o === v || o.id === v.id}
              label="Beer"
              name="beer"
              onChange={this.handleBeerChange}
              options={this.state.brewery ? this.state.allBeers.filter(b => b.brewery.id === this.state.brewery.id) : []}
              renderInput={params => <TextField {...params} label="Beer" variant="outlined" value={this.state.beer} fullWidth /> }
              value={this.state.beer}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              autoSelect={true}
              disabled={this.props.editor ? true : false}
              getOptionLabel={option => option && option.name ? option.name : ""}
              getOptionSelected={(o,v) => o.id === v.id}
             // getOptionSelected={(o,v) => o && v && (o === v || o.id === v.id)}
              label="Package"
              name="package"
              onChange={this.handlePackageChange}
              options={this.state.allPackages}
              renderInput={params => <TextField {...params} label="Size" variant="outlined" value={this.state.package} fullWidth /> }
              value={this.state.package}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Package Date"
              onChange={this.handlePackageDateChange}
              type="date"
              value={this.state.packageDate}
            />
          </Grid>
          {/* <Grid item xs={12}> //TODO: for re-use of this modal in create/edit
            <TextField
              fullWidth
              label="Kick Date"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleKickDateChange}
              value={this.state.kickDate}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Purchased From"
              onChange={this.handlePurchasedFromChange}
              value={this.state.purchasedFrom}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Purchase Date"
              onChange={this.handlePurchaseDateChange}
              type="date"
              value={this.state.purchaseDate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
              label="Purchase Price"
              onChange={this.handlePurchasePriceChange}
              type="number"
              value={this.state.purchasePrice}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: 0 } }}
              label="Deposit"
              onChange={this.handlePurchaseDepositChange}
              type="number"
              value={this.state.purchaseDeposit}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }
}
export default NewKegModal