import React from "react";
import NewBeerForm from "components/NewBeerForm/NewBeerForm";
import UpdateBeerForm from "components/UpdateBeerForm/UpdateBeerForm";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { pourService } from '_services';


class BeerAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          updateBeerId: 0,
          showUpdateForm: false, //TODO: probably can just use the int
          allBeers:[{"Beer":"","Style":"","Brewery":"","ABV":""}]
        }
      this.addBeerHandler = this.addBeerHandler.bind(this)
      this.onCancelUpdate = this.onCancelUpdate.bind(this)
      this.onSaveUpdate = this.onSaveUpdate.bind(this)
  }

  refreshBeers(){
    pourService.getAllBeers().then(b => this.setState({allBeers: b}))
  }

  componentDidMount() {
    this.refreshBeers()
  }

  addBeerHandler(newBeer) {
    this.setState({
      allBeers: this.state.allBeers.concat(newBeer)
    })
  }

  onCancelUpdate(){
    this.setState({updateBeerId: 0, showUpdateForm: false})
  }

  onSaveUpdate(){
    this.setState({updateBeerId: 0, showUpdateForm: false})
  }

  render() {
    document.title = "Manage Beers - pour"
    let updForm = ""
    if (this.state.showUpdateForm){
      updForm =
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary">
          Update
        </CardHeader>
        <CardBody>
        <UpdateBeerForm onCancel={this.onCancelUpdate} onSave={this.onSaveUpdate} beerId={this.state.updateBeerId}/>
          </CardBody>
          </Card>
      </GridItem>
    }
    return (
      <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            All Beers
          </CardHeader>
          <CardBody>
          <MaterialTable
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit Beer',
              onClick: (event, rowData) => {
                this.setState({updateBeerId: rowData.id, showUpdateForm: true})
              }
            }
          ]}
          columns={[
            { title: "id", field:"id", hidden: true },
            { title: "Beer", field: "name" },
            { title: "Style", field: "style.style" },
            { title: "Brewery", field: "brewery.name" },
            { title: "ABV", field: "abv" },
          ]}
          data={this.state.allBeers}
          title="All Beers"
          options={{
              grouping: true,
              pageSize: 10,
              search: true,
              }}
            />
            </CardBody>
            </Card>
        </GridItem>
        {updForm}
        <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            Add New
          </CardHeader>
          <CardBody>
          <NewBeerForm onBeerAdded = {this.addBeerHandler} />
            </CardBody>
            </Card>
        </GridItem>
      </GridContainer>
      </div>
  );}
}

export default BeerAdmin;
